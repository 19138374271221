<template>
  <v-footer
    id="home-footer"
    app
    color="grey darken-4"
    dark
    height="55"
    inset
  >
    <v-row
      class="mx-3"
    >
      <v-col
        cols="12"
      >
        <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
          <template v-for="(s, i) in social">
            <a
              :key="s"
              class="white--text pa-1 pa-md-0"
              href="#"
              v-text="s"
            />

            <v-responsive
              v-if="i < social.length - 1"
              :key="`divider-${s}`"
              class="mx-4 shrink hidden-sm-and-down"
              max-height="24"
            >
              <v-divider vertical />
            </v-responsive>
          </template>
          <v-spacer />
          <div class="body-2 font-weight-light pt-0 pt-md-0">
            &copy; 2022, made for a better
            <v-icon size="20">
              mdi-finance
            </v-icon> by<a
              href="http://udaconsulting.com"
              target="_blank"
            >UDA Consulting</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Twitter',
        'Linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
